//hamburger menu
function createHamburgerMenu () {

  var $win = $(window);
  var ww = window.innerWidth;
  var currentWidth = window.innerWidth;
  var breakpoint = 1024;

  // ---  横幅が変動した時に実行
//  $win.on('load', function () {
    checkGnavWidth();
//  });

  window.addEventListener("resize", function () {
    // ウインドウ横幅が変わっていない場合
    if ( currentWidth == window.innerWidth ) {
      return false;
    }
    checkGnavWidth();
    currentWidth = window.innerWidth; // 横幅を更新

  });

  // -- toggle action
  $('#toggle').on('click', function () {
    $(this).toggleClass('is-toggleActive');
    $('html').toggleClass('open');

    if ( $(this).hasClass('is-toggleActive') ) {
      $('#gnavi').addClass('is-navOpen');
    } else {
      $('#gnavi').removeClass('is-navOpen');
    }

    $('#gnavi').slideToggle( function () { // iOSバグ対策
      if( $(this).hasClass('is-navOpen') ) {
        $(this).css('display','block');
      } else {
        //監視解除
        $(this).css('display','none');
      }
    });

    //スマホでスクロールした際に上下のバーが出てきて、高さが変わるのに対応する。
    if( ww <= breakpoint ) {
      var windowInnerHeight = window.innerHeight;
      var headerHeight = $('.l-header__inner').innerHeight(); //variable.scssの「$headerHeight-sp」と同じ値を取得。
      $('.l-navigation__wrapper').css('height', windowInnerHeight - headerHeight);
    }

  });


  function checkGnavWidth () {
    ww = window.innerWidth;
    $("[data-js='dropDown']").off();
    if( ww > breakpoint ) {
      $('#gnavi').css('display','block');
      $('.l-navigation__wrapper').css('height', '100%');
    } else {
      $('#gnavi').css('display','none');
      $('#toggle').removeClass('is-toggleActive');
      $('html').removeClass('open');
    }
    globalNavInit();
  }

  // --- リサイズ時の初期化
  function globalNavInit () {
    if( ww >= breakpoint ) {
      //PC挙動
      $("[data-js='dropDown']").on('click', function(e){
        e.preventDefault();
        if ($(this).hasClass('isDropdownOpen')) {
          $("[data-js='dropDown']").removeClass('isDropdownOpen');
        } else {
          $("[data-js='dropDown']").removeClass('isDropdownOpen');
          $(this).addClass('isDropdownOpen');
        }
      });
    } else {
      //ハンバーガーメニュー挙動
      $("[data-js='dropDown']").on('click', function(e){
        e.preventDefault();
        if ($(this).hasClass('isDropdownOpen')) {
          $(this).removeClass('isDropdownOpen');
        } else {
          $(this).addClass('isDropdownOpen');
        }
      });
    }
  }


}
